// AppContext.js
import React, { createContext, useState, useEffect } from "react";
import { ACTION_GET, ACTION_POST } from '../utils/AxiosActions'
import { useAuth0 } from '@auth0/auth0-react';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { getAccessTokenSilently,getIdTokenClaims } = useAuth0();



  const createUser = (token)=> {
    console.log('create user')
      try {
        let url = process.env.REACT_APP_USER_INFO_URL
        let config = {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        return new Promise((resolve, reject) => {
            ACTION_POST(url,{}, config)
                .then((result) => {
                  if(result){
                    window.location.href = '/'
                  }
                resolve(result);
                })
                .catch((error) => {
                reject(error);
                });
            });
    } catch (error) {
        console.log(error)
    }
  }
    const getUser = async ()=> {
    console.log('get user')
    try {
        const token = await getAccessTokenSilently();
        let url = process.env.REACT_APP_USER_INFO_URL
        let config = {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        return new Promise((resolve, reject) => {
            ACTION_GET(url, config)
                .then((result) => {
                  getUserProfile()
                  resolve(result);
                })
                .catch((error) => {
                  if(error.status == 404){
                    createUser(token)
                  }
                reject(error);
                });
            });
    } catch (error) {
        console.log(error)
    }
    }

    const getUserProfile = async ()=> {
      try {
          const apiUrl = process.env.REACT_APP_API_URL;
          let url = apiUrl+'/api/fdcpcrm/profile'
          const idTokenClaim = await getIdTokenClaims();
          let config = {
                  headers: {
                      Authorization: idTokenClaim.__raw
                  }
              }
          return new Promise((resolve, reject) => {
              ACTION_GET(url, config)
                  .then((result) => {
                      if(result.user){
                        console.log('set localStorage user ')
                          setUser(result.user);
                          localStorage.setItem('user',JSON.stringify(result.user))
                      }
                  resolve(result);
                  })
                  .catch((error) => {
                    console.log('get userinfo error '+error.status)
                  reject(error);
                  });
              });
      } catch (error) {
          console.log(error)
      }
      }

  useEffect(() => {
    getUser()
  }, []);

  return (
    <AppContext.Provider value={[user]}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;


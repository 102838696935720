import React, { useState,useEffect } from 'react';
import './Members.css';

function Members({ registered }) {
  const [isShopListVisible, setIsShopListVisible] = useState(false);
  
  useEffect(() => {
    document.title = `Members Clubについて | FDCP`;
  }, []);

  const toggleShopList = () => {
    setIsShopListVisible(!isShopListVisible);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    if (value) {
      window.location.href = value;
    }
  };

  return (
    <div className="members-txt">
      <div className="member-content">
        <p className="font-xl padding-side">Members Clubについて</p>
      </div>
      <div className="member-content">
        「エフ・ディ・シィ・プロダクツ メンバーズクラブ」は対象店舗全店でご利用いただける会員プログラムです。<br />
        ご登録いただくと、会員のみの特別な特典をご利用いただけます。<br />
        <span className="font-12"> ※登録・年会費は無料です。</span> <br /><br />
        <b>[ ご入会方法 ]</b><br />
        対象店舗にて商品をご購入の際、ご希望いただいた方へLINE公式アカウントからご登録いただけるメンバーズクラブのご案内をしております。<br />
        <br />
        ご入会前に <a href="/terms" className="underline"><span>ご利用規約</span></a> をお読みください。<br />
        <br />
        <b>[ 会員証 ]</b><br />
        LINEを通じて、会員証を発行いたします。<br />
        <span className="font-12">※会員証に有効期限はございません。</span> <br />
        <br />
        <b>[ 対象ブランド ]</b><br />
        ４℃、４℃ BRIDAL、EAU DOUCE ４℃、Canal ４℃<br />
        <br />
        <b>[ ご利用可能店舗 ]</b>
        <br />
        メンバーズクラブ対象店舗でご利用いただけます。<br />
        <select 
          className="select-link no-border border-bottom padding-right-0" 
          onChange={handleSelectChange}
        >
          <option value="">対象店舗はこちら　▾</option>
          <option value="https://www.fdcp.co.jp/Form/Shop/ShopList.aspx?sbid=4c-jewelry&areaid=0000000001&bid=4c-jewelry">４℃</option>
          <option value="https://4-bridal.jp/shop_list/">４℃ BRIDAL（専門店）</option>
          <option value="https://www.fdcp.co.jp/Form/Shop/ShopList.aspx?sbid=eaudouce4c&areaid=0000000001&bid=eaudouce4c">EAU DOUCE４℃</option>
          <option value="https://www.fdcp.co.jp/Form/Shop/ShopList.aspx?sbid=canal4c&areaid=0000000001&bid=canal4c">Canal ４℃</option>
        </select>
        <br />
        <br />

      <div className="benefits">
        メンバーズクラブ会員特典
      </div>
      <span className="font-12">
        ※詳しくは対象ブランドのショップスタッフ、<br />
        または保証書の内容をご確認ください
      </span>
      <br />
      <br />
      <br />

      <b className="welfare-7"> 商品保証 </b> <br />
      <br />
      <span className="font-12"> ※一部対象外の商品もございます。</span> 
      <br />
      <br />
      ご購入いただいたジュエリーを末永く身に着けて頂くために、責任を持って対応させていただきます。ジュエリーをプレゼントで贈られる際には商品保証サービスの特典をLINEでお贈りいただけます。
<br />
      <span className="font-12"> ※商品保証を受けられる際は本登録が必要です。</span> <br />
      <br />
      <div className="benefits-brand">
        <span className="benefits-brand-span">ジュエリー</span>
      </div>
      </div>
      <div className="benefits-brand-text">
        <p className="indent">
          １．5年保証 <br />
          お買上日より5年間、下記内容の無償修理を承ります。<br />
          <span className="font-12"> ※詳しくは対象店舗のショップスタッフまでお問い合わせください。</span> <br />
        </p>
        <p className="indent-second">
          □チェーンやパーツの取付・交換 <br />
          ネックレス・ブレスレット等は強い負荷がかかると切れてしまう場合がございます。<br />
          その際はチェーンやパーツを取付もしくは交換いたします。
        </p>
        <p className="indent-second">
          □石の留め直し <br />
          ご愛用いただくなかで石が緩んでしまった、または外れてしまった際は、石を留め直しいたします。
        </p>
        <span className="font-12 indent-second"> ※石のご用意は有償となります。</span> <br />
        <p className="indent">
          ２．サイズ直し・刻印入れ サービスお買上日より5年間、身に着けていただくなかで、リングのサイズが合わなくなった場合には、サイズ直し可能な範囲で承ります。<br />

            サイズ直しに伴う刻印の入れ直しもサービスとなります。 <br />
          </p>

          <p className="indent">
          ３．時計の内装修理 2年保証お買上日より2年間、正常なご使用状態で万一故障した場合には、無料にて修理・調整いたします。 <br />
          </p>
      </div>

      <div className="member-content">
        <div className="benefits-brand">
          <span className="benefits-brand-span">ブライダル</span>
        </div>
      </div>

      <div className="benefits-brand-text">
        <p className="indent">
          １．永久保証<br />
          熟練のクラフトマンが生涯にわたり責任を持って見守り続けます。
        </p>
        <p className="indent">
          ２．紛失保証 <br />
          紛失・盗難保証により大切なジュエリーを失くされた場合に、1回限り半額でお作り直しを承ります。 <br />
        </p>

        <p className="indent">
          <span className="font-12">※保証の適用には所定の手続きが必要となります</span>
        </p>
      </div>
    </div>
  );
}

export default Members;

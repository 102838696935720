import React, { useState,useEffect } from "react";
import "./Synopsis.css";

const Synopsis = () => {
  const [activeTab, setActiveTab] = useState("jewelry");

  useEffect(() => {
    document.title = `ご利用規約 | FDCP`;
  }, []);

  return (
    <div className="container-fluid">
      <div>
        <br />
        <div
          className={`synopsis-btn1 ${activeTab === "jewelry" ? "active" : ""}`}
          onClick={() => setActiveTab("jewelry")}
        >
          Jewelry Manual
        </div>
        <div
          className={`synopsis-btn2 ${activeTab === "watch" ? "active" : ""}`}
          onClick={() => setActiveTab("watch")}
        >
          Watch Manual
        </div>
        <div className="clear"></div>
      </div>

      {activeTab === "jewelry" && (
          <div className="synopsis-content1">
          <br />
          <div className="text-center">お手入れ・お取り扱いについて</div>
          <br />
          使用後は、やわらかい布で拭き、一点ずつビニール袋やケースに入れて保管してください。<br />
          また、長くご愛用していただくために定期的なジュエリーの洗浄をおすすめいたします。<br />
          全国の取り扱い店舗にて無償で洗浄いたしております。<br />
          詳しくはお近くの取り扱いショップまでお問い合わせください。<br />
          <br />
          <br />
          <div className="text-center">取り扱い説明書</div>
          <br />
          <div style={{ padding: '0 10px' }}>
            <ul>
              <li>
                ジュエリーは、大変繊細につくられているものです。<br />
                落としたり、ぶつけたり、無理な力で引っ張る、圧力をかける、などは製品の破損の原因になりますので避けてください。<br />
                また、炊事、入浴(特に温泉)、就寝、スポーツをされる際はできるだけお外しください。<br />
                極度な低温やサウナなど高温の場所でご使用されると凍傷や火傷の原因になる恐れがございますので、必ずお外しください。
              </li>
              <br />
              <li>
                ネックレスやブレスレットなどチェーンを使用している製品は、負荷がかかることによりチェーンとフックを繋ぐパーツが外れる場合がございます。
                お客様のお身体を安全に保つための機能です。<br />
                パーツ外れやチェーン切れを防ぐよう、衣類の着脱の際には取り外しをお願いいたします。接続パーツが外れた時は店頭でお直しいたします。
                お近くの取り扱いショップまでお持ちください。
              </li>
              <br />
              <li>
                リングやバングルは、負担がかかることにより変形がおき、美しさを損なったり、石が取れて品質を損なう原因になります。
                荷物を持ち運びする際や、用具を使用する際などは、取り外しをお願いいたします。また、リングを使用されたまま拍手をする、
                電車のつり革を強く握るなども、変形が生じる原因になりますのでご注意ください。
              </li>
              <br />
              <li>
                ピアスは大変繊細につくられているため、無理な力を加えると、ポスト(棒の部分)が外れたり変形する場合がありますので、
                十分ご注意ください。
              </li>
              <br />
              <li>
                シルバー・真鍮・K10PG・K10YGは、素材の特性で空気中の水分、埃、汗等に反応し、黒く変色することがあります。
                長時間の装着や水気に触れたままの放置は避けてください。ご使用の後は、柔らかい布等で拭いてからビニール袋に入れたり布にくるむなどして空気に触れるのを避けて保管してください。
              </li>
              <br />
              <li>
                天然石は、落とす、ぶつける、こすれるなどの衝撃により、破損する場合があります。
                石付きの商品は衝撃を加えたり、衣類の繊維などに引っかけたりすることで、石揺れや石が外れる恐れがあります。
                特にパールなどの接着で石留めしているものは水分に触れると石が取れやすくなる性質がありますので、お取り扱いには十分ご注意ください。
              </li>
              <br />
              <li>
                製品に使用している素材や石は、洗剤・化粧品・香水など化学物質のほか、水にも敏感です。
                これらのものがジュエリーになるべく触れないようご注意ください。
              </li>
              <br />
              <li>
                コーティング製品は摩擦や落下、ぶつけなどの衝撃により、表面コーティングが剥がれますのでお取り扱いには十分ご注意ください。
                また、製品によってコーティングの再加工を承りますので、お近くのショップまでお持ちください。
              </li>
              <br />
              <li>
                力仕事や乳幼児の世話をされる際などは、誤ってお身体に危害を及ぼす恐れがありますのでお取り扱いには十分ご注意ください。
                また、乳幼児が誤って飲み込む危険がありますので、ご使用・保管にはご注意ください。
                <br />
                体質により皮膚に異常を感じたときは、直ちにご使用をおやめいただき、専門医にご相談ください。
              </li>
              <br />
            </ul>
          </div>
        </div>
      )}

      {activeTab === "watch" && (
          <div className="synopsis-content2">
          <br />
          <div className="text-center">お手入れについて</div>
          <br />
          ケース・ガラスの汚れや汗などの水分は、柔らかい布で拭き取ってください。<br />
          <br />
          金属ベルト・プラスチックベルト・ウレタンベルト(ゴムベルト)は水で汚れを洗い落としてください。<br />
          （非防水時計の場合は水分が本体にかからないよう、十分お気をつけください）<br />
          金属ベルトのすき間につまったゴミや汚れは柔らかいハケなどで取り除いてください。<br />
          皮革ベルトは乾いた布などで拭いて汚れを除去してください。<br />
          <br />
          時計を長時間ご使用にならないときは、汗・汚れ・水分などを良く拭き取り、高温・低温・多湿の場所を避けて保管してください。<br />
          <br />
          時計のガラス部分や金属部分(裏ぶた、ベルト、中留め)に保護シールが貼られているときは、ご使用の前に必ずはがしてください。シールのすき間に汗や水分が入り込むと、皮膚のかぶれや金属の腐食の原因となる場合があります。<br />
          <br />
          <br />
          <div className="text-center">時計取り扱い上のご注意</div>
          <br />
          <dl>
            <dd>1.温度について</dd>
            <dd className="padding-left-18">
              直射日光にさらしたり、高温になる所、湿度の高い所には長時間置かないでください。
              故障や電池寿命の短くなる原因となります。<br />
            </dd>
          </dl>
          <dl>
            <dd>2.ショックについて</dd>
            <dd className="padding-left-18">
              落とす、ぶつけるなど、激しいショックを与えないでください。ガラスが割れたり、故障の原因になります。<br />
            </dd>
          </dl>
          <dl>
            <dd>3.磁気について</dd>
            <dd className="padding-left-18">
              強い磁気を発生する磁気器具などに近づけますと、止まったり遅れたり故障の原因になります。<br />
            </dd>
          </dl>
          <dl>
            <dd>4.化学薬品・ガスについて</dd>
            <dd className="padding-left-18">
              化学薬品・ガスの中で長時間のご使用はお避けください。水銀(化合物)や除光液、マニキュアなどが時計に触れると、
              金、銀、銅などの金属メッキが変色します。薬品を扱う方は、特にご注意ください。<br />
            </dd>
          </dl>
          <dl>
            <dd>5.電池について</dd>
            <dd className="padding-left-18">
              電池が切れたまま放置しておきますと、漏液をおこし故障や損傷の原因となりますので、電池交換はお早めにご依頼ください。<br />
            </dd>
          </dl>
          <dl>
            <dd>6.コーティング製品について</dd>
            <dd className="padding-left-18">
              コーティング製品は、摩擦や落下・ぶつけなどの衝撃により、表面コーティングが剥がれますのでお取り扱いには十分
              ご注意ください。<br />
            </dd>
          </dl>
        </div>
      )}
    </div>
  );
};

export default Synopsis;

import React, { useState, useEffect,useContext } from "react";
import './Home.css';
import { useAuth0 } from '@auth0/auth0-react';
import Barcode from './Barcode';
import { Link } from 'react-router-dom';
import { AppContext } from './AppProvider'

const Home = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [user] = useContext(AppContext);

  useEffect(() => {
    document.title = `マイページ | FDCP`;
    if(user?.is_member == false){
      window.location.href = '/member'
    }
    }, [user]);
  return (
    <div className="home-content">
      {/* <Title children="Home" /> */}
      <div className="padding-side">
        {isAuthenticated ? (
          <>
            <div className="home-content">
              <div className="padding-side">
                {/* Include barcode here */}
                {/* You might need to use an img or a Barcode component */}
                <div className="barcode">
                <Barcode
                    isMember={user?.is_member}
                    user={user}
                    offlineMember={user?.offline_member} // or false based on your data
                  />
                </div>
              </div>

              <ul className="menu">
                <Link to="/profile" className="menu-link">
                  <li className="menu-item">
                    <span className="font-lg">会員情報</span>
                  </li>
                </Link>
                <Link to="/order" className="menu-link">
                  <li className="menu-item">
                    <span className="font-lg">ご購入履歴</span>
                  </li>
                </Link>
                <Link to="/certificate" className="menu-link">
                  <li className="menu-item">
                    <span className="font-lg">保証書</span>
                  </li>
                </Link>
                <Link to="/membersclub" className="menu-link">
                  <li className="menu-item">
                    <span className="font-lg">Members Club<br />について</span>
                  </li>
                </Link>
              </ul>

              {/* Hidden zip code section */}
              <div id="zip-code" className="hidden">
                <div className="promo-bg"></div>
                <div className="promo-text">
                  <div className="ts-code-show" style={{ zIndex: 999, textAlign: 'center' }}></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <button onClick={() => loginWithRedirect()}>ログイン</button>
        )}
      </div>
    </div>
  );
};

export default Home;

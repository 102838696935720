import React, { useState,useEffect } from 'react';
import './Contact.css'; // Your styles will go here
import { ACTION_POST } from '../utils/AxiosActions'
import { ToastContainer, toast } from 'react-toastify';
import { useAuth0 } from "@auth0/auth0-react";

const Contact = () => {
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    document.title = `Contact | FDCP`;
    if(localStorage.getItem('user')){
      let user =JSON.parse(localStorage.getItem('user'))
      setFormData({
        id: user?.id, 
        nickname: user?.nickname, 
        email: user?.email, 
        phone_number: user?.phone_number, 
        brandName: '', 
        category: '', 
        content: ''
      })
    }
  }, []);

  const [formData, setFormData] = useState({
    id:'', 
    nickname: '', 
    email:'', 
    phone_number:'', 
    brandName: '', 
    category: '', 
    content: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if(window.confirm("この内容で送信します、よろしいですか？")){
          try {
            const idTokenClaim = await getIdTokenClaims();
            const apiUrl = process.env.REACT_APP_API_URL;
            let url = apiUrl+'/api/fdcpcrm/contact'
            let config = {
                    headers: {
                        Authorization: idTokenClaim.__raw
                    }
                }
                console.log(formData);
            return new Promise((resolve, reject) => {
                ACTION_POST(url, formData,config)
                    .then((response) => {
                        if (response.success == true) {
                            toast.success("完了しました。", {
                                showHideTransition: 'fade', // fade, slide or plain
                                allowToastClose: false, // Boolean value true or false
                                position: 'mid-center', // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values
                                className: "toast-message",
                                icon:false,
                                tapToDismissEnabled:true,
                                autoClose:3000
                              });
                        }
                
                        if (response.success == false) {
                          toast.error("失敗しました", {
                            showHideTransition: 'fade', // fade, slide or plain
                            allowToastClose: false, // Boolean value true or false
                            position: 'mid-center', // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values
                            className: "toast-message",
                            icon:false,
                            tapToDismissEnabled:true,
                            autoClose:3000
                          });
                        }
                        // setUser(response.user)
                        resolve(response);
                    })
                    .catch((error) => {
                    reject(error);
                    });
                });
          } catch (error) {
            console.error('Submi failed:', error);
          }
        }
      // You can send the form data here via an API request
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.nickname) newErrors.nickname = "名前は必須です。";
    if (!formData.email) newErrors.email = "メールアドレスは必須です。";
    if (!formData.category) newErrors.category = "お問い合わせ項目必須です。";
    if (!formData.content) newErrors.content = "お問い合わせ内容必須です。";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="contact">
        <ToastContainer hideProgressBar />
      <p className="font-xl padding-side">Contact</p>
      <div className="members-txt">
        <div className='padding-side'>
          商品・フェア・サービスについて、ご不明点などがございましたら、お近くの対象店舗へお問い合わせください。<br />
          その他、メンバーズクラブについてご不明点などがございましたら、下記フォームに必要事項を入力のうえ、確認ボタンを押してください。<br />
          ＊お問い合わせに関するご注意＊<br />
          メールの設定や使用されているスマートフォンの機種により、弊社からの返信が届かない場合がございます。<br />
          <br />
          5日経過しても返信がない場合はお手数おかけいたしますが、お電話でお問い合わせください。<br />
          <br />
          <br />
          □以下のメールを受信できるよう受信リストの設定をお願い致します。<br />
          <a style={{ float: 'left' }} href="mailto:info@fdcp.co.jp">info@fdcp.co.jp</a>
        </div>
        <br />
        <form onSubmit={handleSubmit}>
          <ul className="ul-items">
            <li className="li-item">
              <div className="input-set">
                <label htmlFor="user_id">メンバーＩＤ　必須</label>
                <input 
                  id="user_id" 
                  type="text" 
                  name="userId" 
                  value={formData.id} 
                  onChange={handleChange} 
                  readOnly 
                />
              </div>
            </li>
            <li className="li-item">
              <div className="input-set">
                <label htmlFor="nickname">お名前</label><label className="require">必須</label>
                <input 
                  id="nickname" 
                  type="text" 
                  name="nickname" 
                  value={formData.nickname} 
                  onChange={handleChange} 
                />
                {errors.nickname && <p className="error-message">{errors.nickname}</p>}
              </div>
            </li>
            <li className="li-item not-first">
              <div className="input-set">
                <label htmlFor="email">メールアドレス</label><label className="require">必須</label>
                <input 
                  id="email" 
                  type="text" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
            </li>
            <li className="li-item not-first">
              <div className="input-set">
                <label htmlFor="phone_number">お電話番号</label>
                <input 
                  id="phone_number" 
                  type="text" 
                  name="phone_number" 
                  value={formData.phone_number} 
                  onChange={handleChange} 
                />
              </div>
            </li>
            <li className="li-item not-first">
              <select 
                className="select-link" 
                name="brandName" 
                value={formData.brandName} 
                onChange={handleChange}
              >
                <option value="">ブランドをお選び下さい</option>
                <option value="４℃">４℃</option>
                <option value="４℃ BRIDAL（専門店）">４℃ BRIDAL（専門店）</option>
                <option value="EAU DOUCE４℃">EAU DOUCE４℃</option>
                <option value="Canal ４℃">Canal ４℃</option>
              </select>
            </li>
            <li className="li-item not-first">
              <div className="input-set">
                <label htmlFor="category">お問い合わせ項目</label><label className="require">必須</label>
                <select 
                  id="category" 
                  name="category" 
                  value={formData.category} 
                  onChange={handleChange}
                >
                  <option value="">お選びください</option>
                  <option value="メンバーズクラブについて">メンバーズクラブについて</option>
                  <option value="メンバー登録について">メンバー登録について</option>
                  <option value="その他">その他</option>
                </select>
                {errors.category && <p className="error-message">{errors.category}</p>}
              </div>
            </li>
            <li className="li-item">
              <div className="input-set">
                <label htmlFor="content">お問い合わせ内容</label><label className="require">必須</label>
                <textarea 
                  id="content" 
                  name="content" 
                  value={formData.content} 
                  onChange={handleChange}
                  rows="8" 
                  cols="80"
                />
                {errors.content && <p className="error-message">{errors.content}</p>}
              </div>
            </li>
          </ul>
          <div className="padding-side">
            ご登録いただいた個人情報は、株式会社エフディシィプロダクツのプライバシーポリシーに基づき、適正な利用と保護および安全策を講じてまいります。
          </div>
          <div className="padding-side">
            <input 
              id="privacy" 
              type="checkbox" 
              value="agree" 
              onChange={() => setFormData({ ...formData, privacy: !formData.privacy })} 
            />
            <a className="read-privacy" href="/privacy">「個人情報の取り扱い」</a><span>に同意する。</span>
            <br />
            <div className="button-wrapper">
              <button type="submit" disabled={!formData.privacy} className="fdcp-btn btn-full">
                確認する
              </button>
            </div>
          </div>
        </form>
        <div className="padding-side">
          <b>お急ぎの場合は、下記のお電話番号よりお問い合わせ下さい。</b>
          <div className="telArea">
            <p className="title">フリーダイヤル</p>
            <p className="tel">
              <a href="tel:0120223177">0120-223-177</a>
            </p>
            <p className="time" style={{ marginBottom: 0 }}>
              [受付時間]平日11:00〜17:30
            </p>
            <span className="title" style={{ color: '#ccc' }}>※水曜日・GW・年末年始除く</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

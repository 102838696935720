import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";

const Barcode = ({ isMember, user, offlineMember }) => {
  const [userId, setUserId] = useState('');

  // Set userId when user prop changes (assuming user is an object with an id)
  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id);
    }
  }, [user]);

  return (
    <div className="barcode-wrapper">
      {isMember ? (
        <p className="font-lg">
          {user?.nickname}さまのメンバーID{''}
          {offlineMember && <span>*</span>}
        </p>
      ) : (
        <p className="font-lg">メンバーID</p>
      )}
      <div style={{ textAlign: 'center' }}>
        <p className="font-sm">店頭で表示する際は画面を明るくしてください</p>
      </div>

      <div
        className="ts-code-show"
        style={{ zIndex: 999, textAlign: 'center' }}
      >
        {/* QR code generation using qrcode.react */}
        {/* <QRCode value={String(userId).padStart(10, '0')} size={100} /> */}
        <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100px", width: "100px" }}
            value={String(userId).padStart(10, '0')}
            viewBox={`0 0 256 256`}
        />
      </div>
      <div style={{ textAlign: 'center',marginTop:'5px' }}>
        <span>{String(userId).padStart(10, '0')}</span>
      </div>
    </div>
  );
};

export default Barcode;

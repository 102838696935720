import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ACTION_GET } from '../utils/AxiosActions'

const GetGuaranteeCard = () => {
    const { guaranteeId } = useParams(); // 获取路由参数
    const { getIdTokenClaims } = useAuth0();

    console.log(guaranteeId)
    useEffect(() => {
        if(localStorage.getItem('user')){
            let user = JSON.parse(localStorage.getItem('user'))
            // console.log(process.env.REACT_APP_LINE_ADD_URL)
            if(!user?.is_follow){
                window.location.href = process.env.REACT_APP_LINE_ADD_URL // to do env
                return
            }
        }
        async function getCard() {
        try {
            const idTokenClaim = await getIdTokenClaims();
            const apiUrl = process.env.REACT_APP_API_URL;
            let url = apiUrl+'/api/fdcpcrm/getcard/'+guaranteeId
            let config = {
                    headers: {
                        Authorization: idTokenClaim.__raw
                    }
                }
            return new Promise((resolve, reject) => {
                ACTION_GET(url, config)
                    .then((result) => {
                        if(result?.user){
                              window.location.href = '/certificate'
                        }
                    resolve(result);
                    })
                    .catch((error) => {
                    reject(error);
                    });
                });
        } catch (error) {
            console.log(error)
        }
    }
    getCard();
  }, []);

  return (
    <div className="guarantee-wrapper">

    </div>
  );
};

export default GetGuaranteeCard;

import React,{ useEffect } from 'react';
import './Faq.css';

const Faq = () => {

  useEffect(() => {
    document.title = `FAQ | FDCP`;
  }, []);

  return (
    <div className="container-fluid">
    <div>
      <p className="font-xl">F<span className="font-lg">A</span>Q よくあるご質問</p>
    </div>
    <div>
      <div className="question padding-box">Q.仮会員とはなんですか。</div>
      <hr />
      <div className="padding-box">
        A.メンバーズクラブ登録手続きの際、友だち登録のみ完了し、会員情報の入力をされていない方を｢仮会員｣といいます。<br />
        ｢仮会員｣は、LINEのお知らせを受け取ることは可能ですが、すべての会員特典が対象外となります。<br />
        ｢新規メンバー登録｣より、登録手続きを完了することで、特典の対象となります。
      </div>
      <br />
      <div className="question padding-box">Q.本会員登録の登録期限はありますか。</div>
      <hr />
      <div className="padding-box">
        A.商品購入日より 1ヶ月以内 の登録手続きをお願いしております。<br />
        ｢新規メンバー登録｣の手続きがお済でない場合、メンテナンスサービス(商品保証)をはじめとした、全ての特典をお受けできませんので、会員登録をご希望の方は｢新規メンバー登録｣より手続きをお願いいたします。
      </div>
      <br />
      <div className="question padding-box">Q.新規メンバー登録できません(会員情報を入力しても先へ進めません)。</div>
      <hr />
      <div className="padding-box">
        A.推奨環境外のデバイスやブラウザーをご利用の場合、登録が完了できないことがございます。<br />
        <br />
        推奨環境はiOS：バージョン11.0以降で動作するiPhoneまたは、Android：バージョン5.0以降が動作するスマートフォンとなります。<br />
        対応環境に含まれる場合でも、OSのバージョンやお客様のご使用環境によっては機能に不具合が起こる可能性やご利用いただけない場合がございますので予めご了承ください。<br />
        会員特典のご利用については、お手数ですが対象店舗までお問い合わせください。
      </div>
      <br />
      <div className="question padding-box">Q.登録情報の確認や変更はできますか。</div>
      <hr />
      <div className="padding-box">
        A.マイページの｢会員情報｣より、ご登録情報を確認・変更いただけます。
      </div>
      <br />
      <div className="question padding-box">Q.購入履歴は確認できますか。</div>
      <hr />
      <div className="padding-box">
        A.マイページの｢ご購入履歴｣よりご確認いただけます。<br />
        購入履歴は反映までにお日にちがかかる場合がございます。<br />
        なお、マイページで表示される購入履歴は、対象店舗で会員証を提示してお買上いただいた商品のみとなります。
      </div>
      <br />
      <div className="question padding-box">Q.｢保証書｣の送付方法を教えてください。</div>
      <hr />
      <div className="padding-box">
        A.｢メンテナンスサービス(商品保証)｣の特典を、LINEを通して他の方へお譲りいただくことができます。<br />
        <span className="under-line">｢新規メンバー登録｣より登録手続きを完了後、</span>下記手順に沿って保証書をお送りください。ご不明点は対象店舗までお問い合わせください。<br />
        <br />
        <div className="padding-box">
          ①LINEトーク画面下部のマイページより｢ご購入履歴｣または｢保証書｣をタップ
        </div>
        <div className="padding-box">
          ②送りたい商品の｢品番｣をタップし保証書を開く
        </div>
        <div className="padding-box">
          ③｢保証書｣の一番下｢LINEで送る｣をタップ
        </div>
        <div className="padding-box">
          ④LINE画面の｢送信先を選択｣より送りたい相手を選択
        </div>
        <div className="padding-box">
          ⑤画面右上｢送信｣をタップし完了
        </div>
        <br />
        ※ご購入いただいたお品物1点につき1回限り有効とさせていただきます。<br />
        ※一部対象外の商品がございます。<br />
        ※お譲りいただける特典は｢メンテナンスサービス(商品保証)｣のみで、優待などその他特典はお譲り<br />
        できません。
      </div>
      <br />
      <div className="question padding-box">Q.｢保証書｣の受け取り方が分かりません。</div>
      <hr />
      <div className="padding-box">
        A.プレゼントの送り主から、<span className="under-line">｢保証書ギフト｣のメッセージがLINEで届いてから、</span>下記方法でお受け取りいただけます。ご不明点は対象店舗までお問い合わせください。<br />
        <br />
        <div className="padding-box">
          ①LINEに｢保証書ギフト｣のメッセージが送り主から届く
        </div>
        <div className="padding-box">
          ②メッセージに記載されている｢URL｣をタップ
        </div>
        <div className="padding-box">
          ③｢エフ・ディ・シィ・プロダクツ メンバーズクラブ｣を｢友だち追加｣
        </div>
        <div className="padding-box">
          ④メンバーズクラブのメッセージ内容を確認し、｢保証書を受け取る｣をタップ
        </div>
        <div className="padding-box">
          ⑤受け取り完了
        </div>
        ※マイページの｢保証書｣よりご確認いただけます。
      </div>
      <br />
      <div className="question padding-box">Q.｢商品保証｣を受けたいのですが、店舗が近くにない場合、配送での修理は可能ですか。</div>
      <hr />
      <div className="padding-box">
        A.ご来店が難しい際には、カスタマーサービスにて承ります。お手数ですが｢contact｣より、お問い合わせください。
      </div>
      <br />
      <div className="question padding-box">Q.スマートフォンの機種変更をした場合、会員情報は引き継げますか。</div>
      <hr />
      <div className="padding-box">
        A.LINEアカウントを引き継いだ場合、新しいスマートフォンでも会員情報が引き継がれ、LINE会員証がご利用いただけます。<br />
        LINEアカウントを引き継がず新しいアカウントを登録された場合、会員情報の引き継ぎが出来かねますので、お手数ですが対象店舗までお問い合わせください。<br />
        お問い合わせの際、メンバーズクラブの会員ＩＤ(会員証QRコードの下に記載の数字)をお知らせください。
      </div>
      <br />
      <div className="question padding-box">Q.LINEメッセージの配信を停止したいのですが。</div>
      <hr />
      <div className="padding-box">
        A.公式アカウント名｢F.D.C.PRODUCTS｣のトークルームからブロックすることで、メッセージの受信を停止いただけます。<br />
        その場合、トーク一覧には表示されたままとなりますので、会員証の表示・購入履歴の閲覧など、引き続きご利用いただけます。
      </div>
      <br />
      <div className="question padding-box">Q.メンバーズクラブのアカウントを消したいのですが。</div>
      <hr />
      <div className="padding-box">
        A.公式アカウント名｢F.D.C.PRODUCTS｣のトークルームを非表示または削除することで、トーク一覧から削除されます(非表示・削除は新着メッセージが届くまでとなります)。<br />
        アカウントを再度表示されたい場合は、お手数ですがお近くの対象店舗までお問い合わせください(QRコードの読み取りをしていただきます)。<br />
        <br />
        <span className="under-line">｢トークの非表示/削除｣設定方法</span><br />
        トークルームを左にスワイプすると、｢非表示｣｢削除｣の文字が出てくるので、どちらか選択ください。
        </div>
		<br />
		<div className="question padding-box">Q.退会したいのですが。</div>
		<hr />
		<div className="padding-box">
			A.マイページの｢退会について｣より、お手続きいただけます。<br />
			退会完了に伴い、会員特典を受けられなくなります。<br />
			退会時のご注意事項をご確認のうえ、手続きをお願いいたします。
		</div>
      <br />
    </div>
    </div>
  );
};

export default Faq;

import React,{ useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
// import './Footer.css';

const Footer = () => {
  // const [user] = useContext(AppContext);
  const [user, setUser] = useState();

  useEffect(() => {
    if(localStorage.getItem('user')){
      let user =JSON.parse(localStorage.getItem('user'))
        setUser(user)
    }
  }, []);

  return (
    <footer className="footer">
      <ul className="submenu">
        <li><Link to="/membersclub">Members Clubについて</Link></li>
        <li><Link to="/terms">ご利用規約</Link></li>
        <li><Link to="/privacy">プライバシーポリシー</Link></li>
        {user?.is_member && (
        <li><Link to="/withdraw">退会について</Link></li>
        )}
        <li><Link to="/faq">FAQ</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
      <div className="padding-side mypage">
        <Link className="fdcp-btn" to="/">マイページへ</Link>
      </div>
    </footer>
  );
};

export default Footer;

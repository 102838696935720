import React,{ useEffect } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const handleBackClick = () => {
    window.history.back();
  };

  useEffect(() => {
    document.title = `プライバシーポリシー | FDCP`;
  }, []);

  return (
    <div className="container-fluid">
      <div>
        <p className="font-xl">プライバシーポリシー</p>
      </div>
      <div>
        <b>個人情報の取扱いについて</b><br />
        <br />
        株式会社エフ・ディ・シィ・プロダクツは、世界に通用する商品やサービスの提供を通じて、お客様をはじめ私たちを取り巻く人々に貢献していきます。そして、お客様に安心して商品やサービスをご利用いただくために、個人情報の保護及びセキュリティ対策の実施は、事業活動の基本であるとともに、社会的責務であると強く認識しております。<br />
        当社では、役員及び従業員に個人情報保護の重要性を認識させ、個人情報の取扱いに関して以下の個人情報保護方針を定め徹底を図ります。<br />
        <br />
        <b>プライバシーポリシー</b><br />
        <br />
        <div className="indent">
          1. 個人情報の収集について<br />
          当社は、個人情報の収集に際してその収集目的を明確にし、収集する際は適法かつ公正な手段により行います。
        </div>
        <br />
        <div className="indent">
          2. 個人情報の利用について<br />
          収集した個人情報の利用は、収集目的の達成のために必要な範囲に限定し、お客様の権利を損なわないよう十分配慮して行います。
        </div>
        <br />
        <div className="indent">
          3. 個人情報の管理について<br />
          当社は、当社の保有する個人情報の紛失、破壊、改ざん、漏洩及び不正なアクセスの防止等を含め、適切な管理のためのセキュリティシステムの維持、社内管理体制の整備等必要な措置を講じます。
        </div>
        <br />
        <div className="indent">
          4. 個人情報の開示、変更、削除について<br />
          ご自身の個人情報の開示、変更、削除を希望される場合には、当社所定の手続きに基づきご本人確認をさせていただいた上で、速やかに開示、変更、削除させていただきます。
        </div>
        <br />
        <div className="indent">
          5. 第三者への開示について<br />
          当社は、お客様からの承諾なしに、個人情報を第三者に開示することはいたしません。ただし、以下のような場合には、例外として個人情報を開示することがあります。<br />
          <div className="indent-min">
            ・当社が従うべき法的義務のために必要な場合
          </div>
          <div className="indent-min">
            ・当社、当社従業員及びお客様の権利または財産が侵害される恐れがあると当社が判断した場合
          </div>
          <div className="indent-min">
            ・お客様または公共の安全性を守るために開示が必要であると当社が判断した場合
          </div>
          <div className="indent-min">
            ・お客様に明示した利用目的の達成に必要な範囲で個人情報の取扱いを委託する場合
          </div>
        </div>
        <br />
        <div className="indent">
          6. 社内体制について<br />
          当社は、従業員に対して社内教育を実施し、個人情報保護についての意識の向上と定期的な内部監査の実施により、個人情報の適正な管理に努めます。
        </div>
        <br />
        <div className="indent">
          7. 法令の遵守と個人情報の取扱いの改善について<br />
          当社は、個人情報の取扱いにおいて適用される法令、規範を遵守するとともに、本ポリシー及び個人情報の取扱いについて、継続的改善を図ってまいります。
        </div>
        <br />
        <b>商標について</b><br />
        <br />
        本サイトに現れるすべての商標およびサービスマークは、当社が所有するか、またはライセンスその他の正当な権原に基づき使用する商標です。これらを無断で使用することは禁止します。<br />
        <br />
        <b>お客様の個人情報の取り扱いについてのお知らせ</b><br />
        <br />
        株式会社エフ・ディ・シィ・プロダクツは、世界に通用する商品やサービスの提供を通じて、お客様をはじめ私たちを取り巻く人々に貢献していきます。そして、お客様に安心して商品やサービスをご利用いただくために、個人情報の保護及びセキュリティ対策の実施は、事業活動の基本であるとともに、社会的責務であると強く認識しております。<br />
        当社では、役員及び従業員に個人情報保護の重要性を認識させ、個人情報の取扱いに関して以下の個人情報保護方針を定め徹底を図ります。<br />
        <br />
        <div className="indent">
          1. お客様の個人情報の利用目的<br />
          (株)エフ・ディ・シィ・プロダクツは、各ショップにおきましてお客様からお預かりした個人情報を、各ブランドの商品情報・イベント情報・アフタ－サ－ビスならびに当社の会社情報等のご案内を差し上げるために利用させていただきます。また、百貨店内のショップでお預かりした個人情報については、各百貨店からお客様に、商品情報・生活情報・アフタ－サ－ビス・各種ご優待等のご案内をさせていただく場合がございますのでご同意ください。
        </div>
        <br />
        <div className="indent">
          2. 保有個人データの開示について<br />
          個人情報の開示に関するお申し出は、大変お手数ですが、お客様情報をお預かりしたショップにお申し付けください。<br />
          ※ご本人確認をさせていただく場合がございますので、ご了承いただけますようお願いいたします。
        </div>
        <br />
        <div className="indent">
          3. 個人情報の取扱いに関する苦情・お問い合わせについて<br />
          個人情報の取扱いに関するお問い合わせ等はお近くの当社ショップ又は下記アドレスにて承りさせていただきます。<br />
          ※インターネットでの個人情報の開示にはお応えできませんので、ご了承いただけますようお願いいたします。
        </div>
        <br />
        <b>個人情報に関するお問い合わせ先</b>
        <br />
        <br />
        本サイトにおける個人情報の取扱いに関するご意見、ご質問などは下記までお問い合わせください。<br />
        【お問い合わせ先】<br />
        〒141-8544<br />
        東京都品川区上大崎2-19-10<br />
        株式会社エフ・ディ・シィ・プロダクツ　総務部<br />
        e-mail : privacy@fdcp.co.jp
        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
          <button className="return-btn" onClick={handleBackClick}>
            戻る
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState, useEffect } from 'react';
import "./GuaranteeCard.css";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ACTION_GET } from '../utils/AxiosActions'

const GuaranteeCard = () => {
    const { itemId } = useParams(); // 获取路由参数
    const [guaranteeCard, setGuaranteeCard] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [shareUrl, setShareUrl] = useState(null);
    const [hasCard, setHasCard] = useState(false);
    const [content, setContent] = useState("");
    const { getIdTokenClaims } = useAuth0();

useEffect(() => {
  document.title = `保証書 | FDCP`;
    async function getGuaranteeCard() {
        const idTokenClaim = await getIdTokenClaims();
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = apiUrl+'/api/fdcpcrm/granteeDetail/'+itemId
        let config = {
            headers: {
                Authorization: idTokenClaim.__raw
            }
            }
        return new Promise((resolve, reject) => {
            ACTION_GET(url, config)
                .then((result) => {
                    console.log(result.guaranteeCard)
                setGuaranteeCard(result.guaranteeCard)
                setQuantity(result.quantity)
                setShareUrl(result.shareUrl)
                setHasCard(result.hasCard)
                setContent(result.content);
                resolve(result);
                })
                .catch((error) => {
                reject(error);
                });
            });
    }
    getGuaranteeCard();
    }, []);

  return (
    <div className="container-fluid">
      <div className="padding-side">
        <p className="font-xl">
          保証書 <span className="pull-right">x {quantity}</span>
        </p>
      </div>
      {guaranteeCard ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {!guaranteeCard.gift_user_id && (
            <div className="guaranteecard-send">
              <a
                href={`//line.me/R/msg/text/?${encodeURIComponent(
                  `✉️保証書をお送りしました。\n\n下記URLをタップ後、マイページの【保証書】よりご確認ください。\n\n｢エフ・ディ・シィ・プロダクツ メンバーズクラブ｣の会員でない方は、お友だち登録後にご確認いただけます。\n\n-----------------------------\n▶︎保証書一覧はこちら\n ${shareUrl} \n-----------------------------\n\n※保証書一覧は、送られる方・受け取られる方、それぞれご本人様の【保証書】一覧が表示されます。\n`
                )}`}
              >
                <button
                  className="btn"
                  style={{
                    color: "#fff",
                    backgroundColor: "#5ac363",
                    borderColor: "#5ac363",
                  }}
                >
                  LINEで送る
                </button>
              </a>
            </div>
          )}
        </div>
      ) : hasCard ? (
        <p>保証書が転送されました</p>
      ) : (
        <p>保証書がありません</p>
      )}
    </div>
  );
};

export default GuaranteeCard;

import React, { useState, useEffect,useContext } from "react";
import "./Profile.css";
import { AppContext } from './AppProvider'
import { Link } from 'react-router-dom';

const Profile = () => {
  const [user] = useContext(AppContext);

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
  };
  useEffect(() => {
    document.title = `会員情報 | FDCP`;
    }, [user]);

  return (
    <div className="profile-container">
    <div className="padding-side">
      <p className="font-xl">会員情報</p>
    </div>

    <ul className="ul-items">
      <li className="li-item">
        <div className="input-set2">
          <label>お名前</label>
          <label>{user?.nickname}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>お名前（フリガナ）</label>
          <label>{user?.name}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>メールアドレス</label>
          <label>{user?.email}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>郵便番号</label>
          <label>{user?.zip_code}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>都道府県</label>
          <label>{user?.prefecture}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>住所1（市区町村）</label>
          <label>{user?.city}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>住所2（番地）</label>
          <label>{user?.address}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>住所3（建物号室）</label>
          <label>{user?.building}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>電話番号</label>
          <label>{user?.phone_number}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>性別</label>
          <label>{user?.gender ? (user?.gender === 'female' ? '女性' : '男性') : ''}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>生年月日</label>
          <label>{formatDate(user?.birthdate)}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>DM受取</label>
          <label>{user?.receive_dm === '1' ? '可' : '否'}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>メールマガジン可否</label>
          <label>{user?.receive_email_newsletter === '1' ? '可' : '否'}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>未婚/既婚</label>
          <label>
            {!user?.is_marry || user?.is_marry === 'null'
              ? '未入力'
              : user?.is_marry === 1
              ? '既婚'
              : '未婚'}
          </label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>結婚記念日</label>
          <label>{formatDate(user?.wedding_anniversary)}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>職業</label>
          <label>{user?.job}</label>
        </div>
      </li>
      <li className="li-item not-first">
        <div className="input-set2">
          <label>ご利用規約</label>
          <label>
            {formatDate(user?.register_time)} 同意済み<br />
            <label className="last_font">
              ご利用規約は
              {/* <a href="/terms">こちら</a> */}
              <Link to="/terms">
                こちら
              </Link>
            </label><br />
            <label className="last_font">
              ※同意を取り消す場合は、退会処理が必要となります。
            </label><br />
            <label className="last_font">
              詳しくは
              {/* <a href="/withdraw">こちら</a> */}
              <Link to="/withdraw">
                こちら
              </Link>
            </label>
          </label>
        </div>
      </li>
    </ul>

    <div className="button-wrapper padding-side">
      {/* <a className="fdcp-btn btn-full" href="/profile/edit">
        変更
      </a> */}
      <Link to="/profile/edit">
          <span className="fdcp-btn btn-full">変更</span>
      </Link>
    </div>
  </div>
  );
};

export default Profile;

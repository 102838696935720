import React, { useState, useEffect,useContext } from "react";
import "./MemberPage.css"; // Import your styles if needed
import Barcode from './Barcode';
import { AppContext } from './AppProvider'
import { Link } from 'react-router-dom';

const MemberPage = () => {
    const [user] = useContext(AppContext);
    useEffect(() => {
      document.title = `メンバーID | FDCP`;
    }, []);
  return (
    <div className="container-fluid member-wrapper">
      {/* Include Barcode Component */}
      {/* <Barcode /> */}
      <Barcode
        isMember={user?.is_member}
        user={user}
        offlineMember={user?.offline_member} // or false based on your data
        />
      {!user?.is_member && (
        <>
          <ul className="member-menu">
            {/* <a className="certificate" href="/certificate">
              <li>
                <span className="font-lg">保証書</span>
              </li>
            </a> */}
            <Link to="/certificate" className="certificate">
                <li className="menu-item">
                  <span className="font-lg">保証書</span>
                </li>
              </Link>
            {/* <a className="membersclub" href="/membersclub">
              <li>
                <span className="font-lg">
                  Members Club<br />について
                </span>
              </li>
            </a> */}
             <Link to="/membersclub" className="membersclub">
                <li className="menu-item">
                  <span className="font-lg">Members Club<br />について</span>
                </li>
              </Link>
          </ul>

          <div className="comment">
            <p className="font-md">
              メンバー限定のサービスや特別な情報をお届けいたします。
            </p>
            <p className="annotation font-md">
              ※現在は「仮登録」となりますので、1ヶ月以内にご登録ください。
            </p>
          </div>
        </>
      )}

      <div>
        {user?.is_member ? (
          // <a className="fdcp-btn btn-full" href="/">
          //   マイページへ
          // </a>
           <Link to="/">
            <span className="fdcp-btn btn-full">マイページへ</span>
          </Link>
        ) : (
          // <a className="fdcp-btn btn-full" href="/profile/create">
          //   新規メンバー登録
          // </a>
           <Link to="/profile/create">
            <span className="fdcp-btn btn-full">新規メンバー登録</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default MemberPage;

import React from "react";
// import loading from "loading.gif";

const Loading = () => (
  <div className="spinner">
    {/* <img src="/assets/fdcp/img/loading.gif" alt="Loading" style={{ width:'100px',height:'100px' }}/> */}
  </div>
);

export default Loading;

import React,{ useEffect } from 'react';
import './TermsOfService.css';  // Assuming you keep styles in a separate CSS file.

const TermsOfService = () => {

  const handleBack = () => {
    window.history.back(-1);
  };

  useEffect(() => {
    document.title = `ご利用規約 | FDCP`;
  }, []);
  
  return (
    <div className="container-fluid">
  <div className="">
    <p className="font-xl">ご利用規約</p>
  </div>
  <div className="">
    第１条(会員の資格)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.本会の会員とは、株式会社エフ・ディ・シィ・プロダクツ(以下、｢当社｣という)が運営する｢エフ・ディ・シィ・プロダクツ メンバーズクラブ｣の利用規約(以下、｢本規約｣という)を承認のうえ、会員登録を申し込まれ、所定の手続きを完了された方をいいます。なお、会員登録の申し込みは本人のみが行うものとし、代理での登録は行えないものとします。
      </div>
      <br />
      <div className="padding-box">
        2.会員登録手続きは、前項の申し込みに対して当社が承諾したときに完了するものとします。ただし、次のいずれかに該当する場合には、当社は会員への入会の申し込みを承諾しないか、承諾後であっても承諾の取消を行う場合があります。
        <div className="padding-second-box">
        (1) 申込者が虚偽の事実を申告した場合
        </div>
        <div className="padding-second-box">
        (2) 第10条第1項に定める禁止事項に該当した場合
        </div>
        <div className="padding-second-box">
        (3) 過去に本規約違反等を理由として、会員特典の会員資格の取消等が行われていたことが判明した場合
        </div>
        <div className="padding-second-box">
        (4) 第三者からの委託を受けて申し込む場合
        </div>
        <div className="padding-second-box">
        (5) その他、当社が会員として不適当と判断した場合
        </div>
      </div>
    </div>
    <br />
    <br />
    第2条(対象店舗)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.会員特典は、以下のブランド・各店舗で利用することができます。
        <div className="padding-second-box">
        (1)４℃、４℃ BRIDAL、Canal ４℃、<br />
        EAU DOUCE ４℃ の各店舗。
        </div>
      </div>
    </div>
    <br />
    <br />
    第3条(会員IDの発行等)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.当社は、会員に対して会員証(以下、｢会員ID｣という)を発行するものとします。会員IDは、会員1人につき1つとし、複数保有できないものとします。
      </div>
      <br />
      <div className="padding-box">
        2.会員は、対象店舗にてスマートフォン等で会員IDの画面を提示することにより、会員特典等を受けることができるものとします。
      </div>
      <br />
      <div className="padding-box">
        3.会員証は、会員本人のみが利用できるものとし、第三者に会員IDを譲渡または貸与できないものとします。
      </div>
    </div>
    <br />
    <br />
    第4条(会員特典) <br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.会員は対象店舗において、対象商品をご購入の際にメンテナンスサービス(商品保証)などの会員特典が付与されます。会員特典の内容詳細は会員専用ページをご確認ください。
      </div>
      <br />
      <div className="padding-box">
        2.会員特典は、事前に通知することなく変更、終了または停止する場合があります。
      </div>
      <br />
      <div className="padding-box">
        3.前項の変更、終了または停止により会員に不利益または損害が生じた場合でも、当社はこれらについて一切責任を負わないものとします。
      </div>
    </div>
    <br />
    <br />
    第5条(登録内容の変更) <br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.会員は、登録した個人情報に変更があった場合は、すみやかに会員専用ページにて変更手続きを行うものとします。
      </div>
      <br />
      <div className="padding-box">
        2.当社は、会員が変更登録を行わなかったことにより何らかの損害が生じたとしても、一切責任を負わないものとします。
      </div>
      <br />
      <div className="padding-box">
        3.対象店舗での変更手続きは行っておりませんので、あらかじめご了承ください。
      </div>
    </div>
    <br />
    <br />
    第6条(LINE IDおよびパスワード) <br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.LINE ID(メールアドレス)およびパスワードの管理および使用は会員の責任とし、使用上の過誤または第三者の不正な使用等については、当社は一切その責任を負わないものとします。
      </div>
      <br />
      <div className="padding-box">
        2.会員は、LINE ID およびパスワードが第三者に使用されていることが判明した場合には、直ちに当社に連絡するものとします。
      </div>
    </div>
    <br />
    <br />
    第7条(会員情報の取扱い) <br />
    <div className="padding-left-14">
      <div className="padding-box">
      1.会員登録時に会員より提供された個人情報は、当社が所有するものとします。<br />
      なお、以下(1)～(8)、(12)の項目は会員登録時に必須の項目となります。<br />
        <div className="padding-second-box">
        (1) 氏名
        </div>
        <div className="padding-second-box">
        (2) 性別
        </div>
        <div className="padding-second-box">
        (3) 生年月日
        </div>
        <div className="padding-second-box">
        (4) メールアドレス
        </div>
        <div className="padding-second-box">
        (5) パスワード
        </div>
        <div className="padding-second-box">
        (6) 郵便番号
        </div>
        <div className="padding-second-box">
        (7) 住所
        </div>
        <div className="padding-second-box">
        (8) 電話番号
        </div>
        <div className="padding-second-box">
        (9) 未婚/既婚
        </div>
        <div className="padding-second-box">
        (10) 結婚記念日
        </div>
        <div className="padding-second-box">
        (11) 職業
        </div>
        <div className="padding-second-box">
        (12) DM送付の要否
        </div>
      </div>
      <br />
      <div className="padding-box">
      2.当社は会員の個人情報を以下の利用目的にのみ使用し、その他の目的では使用いたしません。
        <div className="padding-second-box">
        (1) 会員専用サービス(保証および、アフターサービス)の提供のため
        </div>
        <div className="padding-second-box">
        (2) 商品・キャンペーン・施策のご案内のため
        </div>
        <div className="padding-second-box">
        (3) 会員認証および会員管理のため
        </div>
        <div className="padding-second-box">
        (4) 特典付与のため
        </div>
        <div className="padding-second-box">
        (5) アンケート調査実施のため
        </div>
        <div className="padding-second-box">
        (6) 商品開発または顧客満足度向上策検討のため
        </div>
        <div className="padding-second-box">
        (7) マーケティング分析のため
        </div>
        <div className="padding-second-box">
        (8) その他必要事項の連絡のため
        </div>
      </div>
      <br />
      <div className="padding-box">
      3.当社は、以下の通り会員の個人情報を共同利用いたします。
        <div className="padding-box">
          (1)共同利用者の範囲
          <div className="padding-min-box">
            ・株式会社エフ・ディ・シィ・プロダクツ、株式会社エフ・ディ・シィ・フレンズ(以下、｢当社グループ｣という)
          </div>
          <div className="padding-min-box">
            ・会員が購入した商品の販売店舗を営業する事業者
          </div>
        </div>
        <div className="padding-box">
        (2)共同利用する個人データの項目
          <div className="padding-min-box">
            ・当社グループにおいては、本条第1項に記載の会員登録時に提供された個人データ、および対象店舗での購入履歴(※1)
          </div>
          <div className="padding-min-box">
            ・販売店舗を営業する事業者においては、本条第1項に記載の個人情報のうち、(1)～(8)、および対象店舗での購入履歴(※1)
            <div className="padding-big-box">
              <span className="font-size:12px">(※1)</span>会員が商品を購入した｢店舗名｣は、購入店舗を営業する事業者と当社グループとの間のみの共同利用のため、その他店舗からは閲覧できません。
            </div>
          </div>
        </div>
        <div className="padding-box">
        (3)共同して利用する者の利用目的
          <div className="padding-min-box">
            ・当社グループにおいては、本条第2項に記載の個人情報の利用目的に同じ
          </div>
          <div className="padding-min-box">
            ・対象店舗を営業する事業者においては、本条第2項に記載の利用目的のうち、(1)～(4)、(8)のためのみ
          </div>
        </div>
      </div>
      <br />
      <div className="padding-box">
        4.会員は、当社に対して、当該個人情報の提供後、氏名・住所・お電話番号等について個人情報を開示するよう求めることができます。開示の結果、当該個人情報に誤りがある場合は、会員は当社に対して当該個人情報の訂正または削除を要求することができます。開示、訂正または削除を要求される場合は、当該ご請求がご本人であることを確認したうえで、対応いたします。
      </div>
      <br />
      <div className="padding-box">
        5.当社は、上記に定めるほか、｢個人情報の取扱いについて｣に従って個人情報を適切に取扱い、株式会社エフ・ディ・シィ・プロダクツにおける個人情報保護に関する統括管理者によって個人情報の保護を行っています。
      </div>
      <br />
      <div className="padding-box">
        6.個人情報に関するお問い合わせ先は、下記までお願いいたします。<br />
        【お問い合わせ先】<br />
        〒141-8544<br />
        東京都品川区上大崎2-19-10<br />
        株式会社エフ・ディ・シィ・プロダクツ　総務部<br />
        e-mail : privacy@fdcp.co.jp
      </div>
    </div>
    <br />
    <br />
    第8条(規約の変更)<br />
    <div className="padding-left-14">
      当社は、会員への事前通知・承諾なしに本規約を随時変更することができるものとします。この場合、当社は会員に対して本サイト上で通知するものとし、改定の効力は、本サイト上への掲示時点で生じるものとします。
    </div>
    <br />
    <br />
    第9条(退会) <br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.会員は随時退会できるものとし、会員専用ページにて退会手続きを行うものとします。当社は、会員が第10条第1項に定める禁止事項に該当する場合には、当社の判断で、会員に通知することなく会員を退会させることができるものとします。
      </div>
      <br />
      <div className="padding-box">
        2.退会手続完了後は、会員は会員特典を利用することができず、また会員特典に関わる一切の権利を失うことをあらかじめ了承するものとし、退会手続きを終了する前に、注意事項等を確認の上で退会するものとします。退会により発生した損害、不利益に関して当社は一切責任を負わないものとします。
      </div>
    </div>
    <br />
    <br />
    第10条(禁止事項)<br />
    <div className="padding-left-14">
      <div className="padding-box">
      1. 当社は、会員が会員特典を利用するにあたり、以下の各号に該当する行為を行うことを禁止します。なお、以下の各号の該当性の判断は当社の判断により行われるものとします。<br />
        <div className="padding-second-box">
        (1) 虚偽の氏名、性別、生年月日または自己のものではない電子メールアドレス、住所、電話番号等を用いて登録内容を変更する行為
        </div>
        <div className="padding-second-box">
        (2) 電子メールアドレスおよびパスワードを公開し、または不正に利用する行為
        </div>
        <div className="padding-second-box">
        (3) 公序良俗に反する行為、法令等に違反する行為
        </div>
        <div className="padding-second-box">
        (4) 会員特典の運営を妨げ、その他当社が提供するサービスに支障をきたすおそれのある行為
        </div>
        <div className="padding-second-box">
        (5) その他当社が会員の行為として不適切であると認めた行為
        </div>
      </div>
      <br />
      <div className="padding-box">
        2.当社は、会員による会員特典の利用が前項各号のいずれかに該当すると当社が判断する場合、会員による会員特典の利用を制限、拒絶、あるいは契約の解除ができるものとします。この場合、会員が受ける不利益や損害につき当社は一切の責任を負わないものとします。
      </div>
    </div>
    <br />
    <br />
    第11条(著作権その他知的財産権)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.本サイトに掲載された全ての内容に関する権利は、当社に帰属する権原にもとづき使用するものです。当社は、会員が本サイト等の掲載内容全部、また、会員からの問合せに対する当社からの回答等を、自らの個人利用目的以外に利用したり、ネットワークの内外を問わず、公衆に再提供したり、無断使用・複製することを禁止します。
      </div>
      <br />
      <div className="padding-box">
        2.本サイト等に現れる全ての商標およびサービスマークは、当社の所有または、ライセンスその他の正当な権原にもとづき使用するものであり、当社は、会員がこれらを無断で使用することを禁止します。<br />
        会員が提供した情報等について著作権(著作権法第 27 条および第 28 条に定める権利を含む)その他知的財産権が生じる場合には、会員は情報等にかかる権利を当社に無償で譲渡するものとし、著作者人格権等の権利を行使しないものとします。
      </div>
    </div>
    <br />
    <br />
    第12条(損害賠償)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.会員は、会員特典の利用とその結果について責任を負い、会員特典の利用に関して自己の責に帰すべき事由により他の利用者または第三者に損害を与えた場合、自己の責任と費用をもってこれを解決するものとします。
      </div>
      <br />
      <div className="padding-box">
        2.会員は、会員特典の利用に関して自己の責に帰すべき事由により当社に損害を与えた場合、当社が被った損害を賠償するものとします。
      </div>
    </div>
    <br />
    <br />
    第13条(免責)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.当社は、会員特典の利用または会員特典により提供される情報等の利用により発生した損害に対し、いかなる責任も負わないものとし、一切の損害につき賠償する義務はないものとします。
      </div>
      <br />
      <div className="padding-box">
        2.通信回線やコンピュータ等の障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた改ざん・損害、その他会員特典に関して会員に生じた損害について、当社は一切責任を負わないものとします。
      </div>
      <br />
      <div className="padding-box">
        3.会員特典の利用による、会員同士、会員と会員特典における情報等提供者もしくは会員と第三者との間で生じた紛議について、当社は一切責任を負わないものとします。
      </div>
      <br />
      <div className="padding-box">
      4.当社は会員特典の廃止について、一切責任を負わないものとします。
      </div>
      <br />
      <div className="padding-box">
        5.会員特典において、利用されたログイン用 ID(電子メールアドレス)とパスワードの組み合わせが一致することを所定の方法により確認することにより、当社が会員本人による利用があったものとみなしたときは、それらが盗用、不正使用その他の事情により会員本人以外の第三者が利用している場合であっても、それにより生じた損害について、当社は一切責任を負わないものとします。 
      </div>
      <br />
      <div className="padding-box">
        6.会員の登録情報に変更が生じた場合に変更登録がなされなかったことにより生じた損害について、当社は一切責任を負わないものとします。
      </div>
      <br />
      <div className="padding-box">
        7.本条の規定は、当社に故意もしくは重過失がある場合には適用しません。何らかの理由により当社が責任を負う場合であっても、当社は、会員の損害につき、過去12か月間に会員が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないものとします。 
      </div>
    </div>
    <br />
    <br />
    第14条(会員特典の保守) <br />
    <div className="padding-left-14">
      当社は、会員特典の稼動状態を良好に保つために、以下の各号の場合、会員に事前に通知を行うことなく、会員特典の提供の全部または一部を中止することができるものとします。<br />
      なお、当社はかかるサービスの中止により、会員に発生した損害、不利益に関して一切責任を負わないものとします。<br />
      <div className="padding-second-box">
        (1) システムの定期保守および緊急保守の場合
      </div>
      <div className="padding-second-box">
        (2) 火災、停電、第三者による妨害行為等により、システムの運用が困難になった場合
      </div>
      <div className="padding-second-box">
        (3) その他、止むを得ずシステムの停止が必要と当社が判断した場合
      </div>
    </div>
    <br />
    <br />
    第15条(Cookieの利用)<br />
    <div className="padding-left-14">
      <div className="padding-box">
        1.本サイトにおいて、以下に掲げる目的のために、一部のコンテンツで Cookie(クッキー)を利用するものとします。<br />
        本サイトにおける Cookie の機能とは、お客さまが本サイトをご覧になったという情報を、そのお客さまのコンピュータ内に記憶させておく機能のことであり、当該Cookieを通じて収集する情報には、電子メールアドレスや氏名等の｢個人を特定できる情報｣は一切含まれません。<br />
        <div className="padding-second-box">
          (1) 本サイトに複数のページにまたがるアプリケーションがある場合に、より便利に本サイトをご覧いただくために必要な情報を一時的に保管する目的
        </div>
        <div className="padding-second-box">
          (2) お客さまが認証サービスにログインされているとき、保存されているお客さまの登録情報を参照して、お客さまごとにカスタマイズされたサービスを提供できるようにする目的
        </div>
        <div className="padding-second-box">
          (3) 本サイトを通じてより良いサービスを提供していくために、当社が本サイト上のお客さまのアクセス傾向を分析する目的
        </div>
        <div className="padding-second-box">
          (4) お客さまが興味を持っている内容や、本サイト上での利用状況をもとに、最も適切な広告を他社ウェブサイト上で表示する目的
        </div>
        <div className="padding-second-box">
          (5) セキュリティー保持のため、ご利用から一定の時間が経過したお客さまに対してパスワードの再入力(再認証)を促す目的
        </div>
      </div>
      <br />
      <div className="padding-box">
        2.お客さまが会員特典を適切に利用するためには、前項を承諾し、Cookie を受け付けることが条件となります。<br />
        お客さまはブラウザで Cookie を拒否するための設定を行うこともできますが、その場合、一部が適切に機能しなくなったり、使えなくなったりするコンテンツが含まれることを予め承諾するものとします。
      </div>
    </div>
    <br />
    <br />
    第16条(準拠法及び管轄裁判所)<br />
    <div className="padding-left-14">
      本サイトならびに本規約の解釈および適用は、日本国法に準拠します。<br />
      また、本サイトおよび会員特典に関わるすべての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
    </div>
    <br />
    以上<br />
    制定日　2019年9月5日<br />
    改定日　2020年8月1日<br />
    <br /><br />
    <div style={{ textAlign: "center" }}>
      <button className="return-btn" onClick={handleBack}>
        戻る
      </button>
    </div>
  </div>
</div>
  );
}

export default TermsOfService;

import React, {  useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';

import Home from './pages/Home';
import Profile from './pages/Profile';
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import Members from './pages/Members';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Withdraw from './pages/Withdraw';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import Order from './pages/Order';
import Synopsis from './pages/Synopsis';
import GuaranteeCard from './pages/GuaranteeCard';
import GuaranteeList from './pages/GuaranteeList';
import GetGuaranteeCard from './pages/GetGuaranteeCard';
import ProfileEdit from './pages/ProfileEdit';
import ProfileCreate from './pages/ProfileCreate';
import MemberPage from './pages/MemberPage';
import AppProvider from './pages/AppProvider'

const ProtectedRoute = ({ component:Component, ...rest }) => {
    const { isAuthenticated,loginWithRedirect,getAccessTokenSilently,getIdTokenClaims } = useAuth0();
    if (!isAuthenticated) {
      loginWithRedirect();
      return null; // Render nothing while redirecting
    }
    const checkToken = async () => {
      if (isAuthenticated) {
        try {
           await getAccessTokenSilently();
           await getIdTokenClaims();
          // console.log('id token:', idTokenClaim.__raw);
        } catch (error) {
          console.log(error)
          loginWithRedirect();
          return null; 
        }
      }
    };

    checkToken();
    return (
      <AppProvider>
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? <Component {...props} />  : <Redirect to="/" />
        }
      />
    </AppProvider>
  );
};

const App = () => {
  const { isLoading, error } = useAuth0();

  useEffect(() => {
    
  }, []);
  
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <div className="App">
      {/* <AppProvider> */}
        <Header />
      {/* </AppProvider> */}
        <main className="main">
          <Switch>
            <ProtectedRoute path="/" exact component={Home} />
            <ProtectedRoute path="/membersclub" exact component={Members} />
            <ProtectedRoute path="/terms" exact component={TermsOfService} />
            <ProtectedRoute path="/privacy" exact component={PrivacyPolicy} />
            <ProtectedRoute path="/withdraw" exact component={Withdraw} />
            <ProtectedRoute path="/faq" exact component={Faq} />
            <ProtectedRoute path="/contact" exact component={Contact} />
            <ProtectedRoute path="/profile/edit" component={ProfileEdit} />
            <ProtectedRoute path="/profile/create" component={ProfileCreate} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/member" component={MemberPage} />
            <ProtectedRoute path="/order" component={Order} />
            <ProtectedRoute path="/synopsis" component={Synopsis} />
            <ProtectedRoute path="/certificate/get/:guaranteeId" component={GetGuaranteeCard} />
            <ProtectedRoute path="/certificate/show/:itemId" component={GuaranteeCard} />
            <ProtectedRoute path="/certificate" component={GuaranteeList} />
          </Switch>
        </main>
        {/* <AppProvider> */}
          <Footer />
        {/* </AppProvider> */}
      </div>
    </Router>
  );
};

export default App;

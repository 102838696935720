import React, { useState,useEffect } from 'react';
import './Withdraw.css';  // Assuming you keep styles in a separate CSS file.
import { ACTION_POST } from '../utils/AxiosActions'
import { useAuth0 } from "@auth0/auth0-react";

const Withdraw = () => {
  const [step, setStep] = useState(1); // To track which step the user is on
  const { logout,getIdTokenClaims } = useAuth0();

  const handleNextStep = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    document.title = `退会 | FDCP`;
  }, []);

  const handleCancelMembership = async() => {
    // Handle the form submission for cancellation
    // This would be where you handle the actual cancellation logic
          // alert('退会処理が完了しました');
          const idTokenClaim = await getIdTokenClaims();
          const apiUrl = process.env.REACT_APP_API_URL;
          let url = apiUrl+'/api/fdcpcrm/withdraw'
          let config = {
              headers: {
                Authorization: idTokenClaim.__raw
              }
            }
          return new Promise((resolve, reject) => {
            ACTION_POST(url,{}, config)
                .then((result) => {
                  localStorage.clear();
                  logout({
                      logoutParams: {
                        returnTo: window.location.origin,
                      }
                  })
                  resolve(result);
                })
                .catch((error) => {
                  console.log(error)
                  reject(error);
                });
            });
  };

  return (
    <div className="container-fluid">
      <div>
        <p className="font-xl">退会時注意事項</p>
      </div>
      <div>
        {step === 1 && (
          <div className="first">
            <br />
            退会時注意事項に同意のうえ、次の画面へお進みください。<br />
            <br />
            退会時注意事項<br />
            <br />
            退会完了に伴い、購入履歴だけでなく、商品保証サービス特典など、<br />
            全て失効いたします。<br />
            退会処理を取り消したり、削除された会員情報を元に戻すことはできません。 <br />
            メッセージ配信も停止となります。<br />
            <br />
            <div className="button-wrapper">
              <button className="fdcp-btn btn-full" onClick={handleNextStep}>
                同意して次へ
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="second">
            <br />
            <br />
            以下のご注意をお読みになったうえ、「上記を確認して退会する」<br />
            ボタンを押してください。<br />
            <br />
            ご注意<br />
            ・「エフ・ディ・シィ・プロダクツ メンバーズクラブ」から退会すると、会員向けのサービスが<br />
            全てご利用いただけなくなります。ご購入履歴の情報や商品保証もすべて<br />
            無効となりますのでご注意ください。<br />
            <br />
            ・退会後に再登録しても退会以前の会員向けサービスの履歴を<br />
            取得することはできませんので、ご了承ください。<br />
            <br />
            ・削除した情報を元に戻すことはできませんのでご注意ください。<br />
            <br />
            ・利用者の公式LINEアカウントについては、登録解除（ブロック）をもって<br />
            連携を解除することができます。<br />
            <br />
            <div className="button-wrapper">
              <button className="fdcp-btn btn-full" onClick={handleCancelMembership}>
                上記を同意して退会する
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="third">
            <br />
            <span className="font-xl">退会手続き</span><br />
            退会時注意事項に同意のうえ、次の画面へお進みください。<br />
            退会手続きを行う場合は、「退会する」ボタンを選択してください。<br />
            <br />
            <form onSubmit={(e) => {
              e.preventDefault();
              handleCancelMembership();
            }}>
              <div className="button-wrapper">
                <button type="submit" className="fdcp-btn btn-full">
                  退会する
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Withdraw;

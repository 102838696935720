import React, { useState,useEffect } from 'react';

function Header() {
  const [isMenuActive, setIsMenuActive] = useState(false);
  // const [user] = useContext(AppContext);
  const [user, setUser] = useState();

  useEffect(() => {
    if(localStorage.getItem('user')){
      let user =JSON.parse(localStorage.getItem('user'))
        setUser(user)
    }
  }, []);

  const handleHamburgerClick = () => {
    if (!isMenuActive) {
      document.querySelector('.corporateNavi').scrollTop = 0;
    }
    setIsMenuActive(!isMenuActive);
  };

  const handleCloseClick = () => {
    setIsMenuActive(false);
  };

  return (
    <header className="header corporateHeader">
      <div className="members_logo">
        <img src="/assets/fdcp/img/members_logo.png" alt="Members Logo" />
      </div>
      <div className="humbarger" onClick={handleHamburgerClick}>
        <img src="/assets/fdcp/img/icon_menu.gif" alt="Hamburger Menu" />
      </div>
      <div className={`corporateNavi ${isMenuActive ? 'active' : ''}`}>
        <ul className="submenu">
          <li><a href="/membersclub">Members Clubについて</a></li>
          <li><a href="/terms">ご利用規約</a></li>
          <li><a href="/privacy">プライバシーポリシー</a></li>
          {user?.is_member && (
          <li><a href="/withdraw">退会について</a></li>
          )}
          <li><a href="/contact">Contact</a></li>
          <li className="header-close" onClick={handleCloseClick}>
            <span>CLOSE</span>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;

import React, { useState, useEffect } from 'react';
import { ACTION_GET } from '../utils/AxiosActions'
import { useAuth0 } from "@auth0/auth0-react";
import './Order.css'

const Order = () => {
  const [orders, setOrders] = useState([]);
  const { getIdTokenClaims } = useAuth0();
  // Fetch orders from API
//   useEffect(async () => {
//     // fetch('/api/orders') // Replace with your API endpoint
//     //   .then((response) => response.json())
//     //   .then((data) => setOrders(data))
//     //   .catch((error) => console.error('Error fetching orders:', error));
//     try {
//         let token = await getAccessTokenSilently();
//         if(token){
//             const data = await getOrders(token)
//             console.log(data.orders)
//             setOrders(data.orders)
//         }
//     } catch (error) {
//         setOrders([])
//     }
//   }, []);

  useEffect(() => {
    document.title = `ご購入履歴 | FDCP`;
    async function getOrders() {
        const idTokenClaim = await getIdTokenClaims();
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = apiUrl+'/api/fdcpcrm/orders'
        let config = {
            headers: {
              Authorization: idTokenClaim.__raw
            }
          }
        return new Promise((resolve, reject) => {
            ACTION_GET(url, config)
              .then((result) => {
                setOrders(result.orders)
                resolve(result);
              })
              .catch((error) => {
                setOrders([])
                reject(error);
              });
          });
    }
    getOrders();
  }, []);

  return (
    <div className="container-fluid">
      <div>
        <p className="font-xl">
          ご購入履歴{' '}
          <span className="pull-right font-sm" style={{ textDecoration: 'underline' }}>
            <a href="/synopsis">お手入れ・お取り扱いについて</a>
          </span>
        </p>
      </div>
      <ul className="ul-orders">
        {orders.map((order) => (
          <li key={order.id} className="li-order" onClick={() => console.log('Order clicked')}>
            <div className="order-header">
              <span className="store-name">{order.store_name || ''}</span>
              <span className="annotation">{order.consume_date || new Date(order.created_at).toLocaleDateString()}</span>
            </div>
            <ul className="ul-item">
              {order.items.map((item, index) =>
                item.quantity > 0 ? (
                  <a key={item.id} className="a-reset" href={`/certificate/show/${item.id}`}>
                    <li className={`li-item ${index > 0 ? 'not-first' : ''}`}>
                      <div className="item-desc font-sm">
                        {item.has_back === 1 && (
                          <span className="annotation font-sm" style={{ backgroundColor: 'pink' }}>
                            {new Date(order.updated_at).toLocaleDateString()}に返品されました
                          </span>
                        )}
                        <span className="item-name">{item.product_name}</span>
                        <br />
                        <span className="annotation">
                          {item.product ? (
                            <>
                              品番{' '}
                              123123123
                            </>
                          ) : (
                            <>
                              問い合わせ番号
                              {item.product_sku}
                            </>
                          )}
                          {item.product && (
                            <>
                              # {item.size_code || ''}
                            </>
                          )}
                        </span>
                        <span className="annotation item-quantity">× {item.quantity}</span>
                      </div>
                    </li>
                  </a>
                ) : null
              )}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Order;

import React, { useState, useEffect } from 'react';
import "./GuaranteeList.css"; // Assuming styles are moved to a separate CSS file.
import { useAuth0 } from "@auth0/auth0-react";
import { ACTION_GET } from '../utils/AxiosActions'

const GuaranteeList = () => {
    const [guaranteeLists, setGuaranteeLists] = useState(null);
    const [hasCard, setHasCard] = useState(false);
    const { getIdTokenClaims } = useAuth0();
    useEffect(() => {
      document.title = `保証書一覧 | FDCP`;
        async function getGuaranteeList() {
            try {
              const idTokenClaim = await getIdTokenClaims();
              const apiUrl = process.env.REACT_APP_API_URL;
                let url = apiUrl+'/api/fdcpcrm/granteeList'
                let config = {
                        headers: {
                            Authorization: idTokenClaim.__raw
                        }
                    }
                return new Promise((resolve, reject) => {
                    ACTION_GET(url, config)
                        .then((result) => {
                            console.log(result.granteeLists)
                            setGuaranteeLists(result.granteeLists)
                            setHasCard(result.hasCard)
                        resolve(result);
                        })
                        .catch((error) => {
                        reject(error);
                        });
                    });
            } catch (error) {
                console.log(error)
            }
        }
        getGuaranteeList();
        }, []);
  return (
    <div className="container-fluid">
    <div>
      <p className="font-xl">
        保証書一覧
        <span className="pull-right font-sm" style={{ textDecoration: "underline" }}>
          <a href="/synopsis">お手入れ・お取り扱いについて</a>
        </span>
      </p>
    </div>

    {guaranteeLists && guaranteeLists.length > 0 ? (
      <ul className="ul-item">
        {guaranteeLists.map((guaranteeCard, key) => {
          return (
            <a
              key={guaranteeCard.order_item_id}
              className="a-reset"
              href={`/certificate/show/${guaranteeCard.order_item_id}`}
            >
              <li className={`li-item ${key > 0 ? "not-first" : ""}`}>
                <div className="item-desc font-sm">
                  <span className="item-name">
                    {guaranteeCard.product_name}
                  </span>
                  <br />
                  <span className="annotation">
                    {guaranteeCard.hinban ? (
                      <>
                        品番
                        {guaranteeCard.hinban}
                      </>
                    ) : (
                      <>
                        問い合わせ番号
                        {guaranteeCard.product_sku}
                      </>
                    )}
                    {guaranteeCard.hinban && (
                      <>
                        #
                        {guaranteeCard.size_code}
                      </>
                    )}
                  </span>
                  <span className="annotation item-quantity">
                    × {guaranteeCard.quantity}
                  </span>
                  <br />
                  <br />
                  <span className="item-price">
                    保証書有効期限 {guaranteeCard.expire_date}
                  </span>
                </div>
                {guaranteeCard.gift_user_id && (
                  <label className="gift-label" htmlFor="">
                    gift
                  </label>
                )}
              </li>
            </a>
          );
        })}
      </ul>
    ) : (
      <div>
        {hasCard ? (
          <p>保証書が転送されました</p>
        ) : (
          <p>保証書がありません</p>
        )}
      </div>
    )}
  </div>
  );
};

export default GuaranteeList;